// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const digsigCertApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratedDigsig: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/ddd-value/get-distinct-ddd-entry-and-value`,
          params: params,
        };
      },
      providesTags: ["GetGeneratedDigsig"],
    }),
    deleteGeneratedDigsig: builder.mutation<any, any>({
      query: (uid) => {
        return {
          url: `/ddd-value/delete-ddd-entry-and-value/${uid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeleteGeneratedDigsig", "GetGeneratedDigsig"],
    }),
    createGenDig: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/generated-digsig/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateGenDig", "GetGeneratedDigsig"],
    }),
    // getKeyMngByUid: builder.mutation<any, any>({
    //   query: (params) => {
    //     return {
    //       url: `/digsig-key/get-dsg-key-by-uid/${params}`,
    //       method: "GET",
    //     };
    //   },
    //   invalidatesTags: ["GetKeysListMngByUid"],
    // }),
    // /digsig-key/get-dsg-key-by-uid/
    //TODO this endpoint to get detail for key management
  }),
});

export const {
  useGetGeneratedDigsigQuery,
  useDeleteGeneratedDigsigMutation,
  useCreateGenDigMutation,
} = digsigCertApiSlice;
