import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const GeneratedDigsigSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [dddName, setDddName] = useState("");
  const [dddEntryCategory, setDddEntryCategory] = useState("");
  const [status, setStatus] = useState("");
  const [enrolledStart, setEnrolledStart] = useState("");
  const [enrolledEnd, setEnrolledEnd] = useState("");

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    dddName: string;
    dddEntryCategory: string;
    status: string;
    enrolledStart: string;
    enrolledEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      dddName: "",
      dddEntryCategory: "",
      status: "",
      enrolledStart: "",
      enrolledEnd: "",
    },
  });

  const statusDropdown = [
    {
      label: "Generated",
      value: "generated",
    },
    {
      label: "Not Generated",
      value: "not generated",
    },
  ];

  const handleDddName = (e: string) => {
    setDddName(e);
  };
  const handleDddEntryCategory = (e: string) => {
    setDddEntryCategory(e);
  };
  const handleStatus = (e: any) => {
    // setStatus(e.value);
    console.log("haha ===> ", e);
    if (e !== null) {
      setStatus(e.value);
    } else {
      setStatus("");
    }
  };
  // const handleStatusSelect = (e: any) => { setUserActive(e.value); };
  const handleEnrolledStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(enrolledEnd))) {
        setAllDate(NewDate);
      } else {
        setEnrolledStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      // deviceId: deviceId,
      // hostname: hostname,
      // enrolledStart: date,
      // enrolledEnd: date,
      // status: userActive,
      dddName,
      dddEntryCategory,
      status,
      enrolledStart: date,
      enrolledEnd: date,
    });
    setEnrolledStart(date);
    setEnrolledEnd(date);
  };
  const handleEnrolledEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setEnrolledEnd(NewDate);
  };
  const conditionCheck = () => {
    if (enrolledStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Created",
      });
      reset({
        // deviceId: deviceId,
        // hostname: hostname,
        // enrolledStart: "",
        // enrolledEnd: "",
        // status: userActive,
        dddName,
        dddEntryCategory,
        status,
        enrolledStart: "",
        enrolledEnd: "",
      });
      setEnrolledEnd("");
      return false;
    } else {
      return true;
    }
  };

  useDebouncedEffect(
    () => setSearchFilterDebounced(dddName),
    [dddName],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(dddEntryCategory), [dddEntryCategory], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(status), [status], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledStart),
    [enrolledStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledEnd),
    [enrolledEnd],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      dddEntryName:dddName,
      dddEntryCompany:dddEntryCategory,
      status: status,
      createdFrom: enrolledStart,
      createdTo: enrolledEnd,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="dddName"
          errors={errors}
          control={control}
          required
          placeholder="Entry Name"
          maxLength={100}
          isLoading={false}
          onChange={handleDddName}
        />
        <InputForm
          name="dddEntryCategory"
          errors={errors}
          control={control}
          required
          placeholder="Company"
          maxLength={100}
          isLoading={false}
          onChange={handleDddEntryCategory}
        />
        <DatePickerForm
          name="enrolledStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Input From"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledStart}
        />
        <DatePickerForm
          name="enrolledEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Input to"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledEnd}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={statusDropdown}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatus}
          isClear
        />
      </Box>
    </Box>
  );
};

export default GeneratedDigsigSearch;
