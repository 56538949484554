// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const domainAuthorCertApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDACert: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/da-certificate/list`,
          params: params,
        };
      },
      providesTags: ["GetDACert"],
    }),
    // deleteKeyMng: builder.mutation<any, any>({
    //   query: (body) => {
    //     return {
    //       url: `/key/delete`,
    //       method: "DELETE",
    //       body,
    //     };
    //   },
    //   invalidatesTags: ["DeleteKeyMng", "GetKeysListMng"],
    // }),
    // getKeyMngByUid: builder.mutation<any, any>({
    //   query: (params) => {
    //     return {
    //       url: `/digsig-key/get-dsg-key-by-uid/${params}`,
    //       method: "GET",
    //     };
    //   },
    //   invalidatesTags: ["GetKeysListMngByUid"],
    // }),
    // /digsig-key/get-dsg-key-by-uid/
    //TODO this endpoint to get detail for key management 
  }),
});

export const { useGetDACertQuery } = domainAuthorCertApiSlice;
