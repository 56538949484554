import IRoutes from "../interfaces/IRoutes";
import ApplicationLog from "../pages/log/applicationLog";
import LogEvents from "../pages/log/KeyUsageLog";

const LogRoute: Array<IRoutes> = [
    {
        element: <LogEvents />,
        path: "/key-user-log",
    },
    {
        element: <ApplicationLog />,
        path: "/application-log",
    }, 
];

export { LogRoute };
