import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  RadioForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { useGetKeyMngByUidMutation } from "../../store/feature/service/keyApiSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import domainAuthorCert from "../../utils/validation/domainAuthorCert";

const DomainAuthorCertField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");

  // const {
  //   editDataOcspConfig,
  //   isLoadingOcspConfig,
  //   isLoadingEvent,
  //   eventFlag
  // } = useAppSelector((state: RootState)=> state.satpasDevice)
  // const {
  //   uc_ocsp_config_uid,
  //  } = editDataOcspConfig
  const [getKeyMngByUid, { data, isError, isSuccess }] =
    useGetKeyMngByUidMutation();

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    domainAutCert: string;
    company: string;
    certCN: string;
    certSN: string;
    uploadCert: string;
    certLvl: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(domainAuthorCert),
    defaultValues: {
      domainAutCert: "",
      company: "",
      certCN: "",
      certSN: "",
      uploadCert: "",
      certLvl: "",
      status: "",
    },
  });

  // REGION: SET FORM DATA
  // useEffect(() => {
  //   if (editFgroup) {
  //     reset({
  //       uid: uc_ocsp_config_uid,
  //       encryptedString: "",
  //       publicKey: "",
  //     });
  //   } else {
  //     reset();
  //   }
  // }, [ editDataOcspConfig ]);

  // REGION: CHANGE STATE
  useEffect(() => {
    getKeyMngByUid(idParam.id);
  }, [idParam]);

  useEffect(() => {
    if (data?.data) {
      console.log("data ===> ", data);
      const keyData = data?.data;
      setValue("domainAutCert", keyData.dsg_key_name);
      setValue("company", keyData.dsg_key_creation_date);
      setValue("certCN", keyData.dsg_key_expiry_date);
      setValue("certSN", keyData.dsg_key_revoke_date);
      setValue("uploadCert", keyData.dsg_key_revoke_reason);
      setValue("certLvl", keyData.dsg_ddd_name);
      setValue("status", state.createdAt);
    }
  }, [data]);

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    // if (editFgroup) {
    //   const updateData = { }
    //   dispatch(updateOcspConfiguration(updateData));
    // } else {
    //   const createData = { }
    //   dispatch(createOcspConfiguration(createData));
    // }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate('/domain-authority-certificate')
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  const handleDestroy = (e: any) => {
    console.log("handle revoke ===> ");
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];
  const optCertLvlSelect = [
    {
      label: "Domain Authority (DA)",
      value: "Domain Authority (DA)",
    },
    {
      label: "Intermediary",
      value: "Intermediary",
    },
    {
      label: "Root",
      value: "Root",
    },
  ];

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup
            ? "Update Domain Authority Certificate"
            : "Upload Domain Authority Certificate"}
        </Typography>
        <InputForm
          name="domainAutCert"
          label="Domain Authority Certificate Name"
          placeholder="Type Domain Authority Certificate Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />

        <InputForm
          name="company"
          label="Company"
          placeholder="Type Company"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="certCN"
          label="Certificate Common Name"
          placeholder="Type Certificate Common Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="certSN"
          label="Certificate Serial Number"
          placeholder="Type Certificate Serial Number"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="uploadCert"
          label="Upload DA/Root Certificate"
          placeholder="Type Upload DA/Root Certificate"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="file"
        />
        <RadioForm
          name="certLvl"
          label="Certificate Level"
          defaultValue={0}
          options={optCertLvlSelect}
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          vertical
        />
        <SelectFormm
          name="status"
          label="Status"
          defaultValue={0}
          options={optStatusSelect}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            type="button"
            color="gray"
            onClick={handleRevoke}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            {editFgroup ? 'Update' : 'Upload'}
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default DomainAuthorCertField;
