import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const ApplicationLogSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [module, setModule] = useState("");
  const [functionName, setFunctionName] = useState("");
  const [severity, setSeverity] = useState("");
  const [type, setType] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [createdStart, setCreatedStart] = useState('');
  const [createdEnd, setCreatedEnd] = useState('');
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    module: string;
    functionName: string;
    severity: string;
    type: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      module: "",
      functionName: "",
      severity: "",
      type: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Low",
      value: "LOW",
    },
    {
      label: "Medium",
      value: "MEDIUM",
    },
    {
      label: "High",
      value: "HIGH",
    },
  ];

  const handleModule = (e: string) => {
    setModule(e);
  };
  const handleFunctionNameSelect = (e: any) => {
    setFunctionName(e);
  };
  const handleSeveritySelect = (e: any) => {
    e !== null ? setSeverity(e.value) : setSeverity("");
  };
  const handleTypeSelect = (e: any) => {
    setType(e);
  };


  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(createdEnd))) {
        setAllDate(NewDate);
      } else {
        setCreatedStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  }
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setCreatedEnd(NewDate);
  };

  const setAllDate = (date: string) => {
    reset({
      module: module,
      functionName: functionName,
      severity: severity,
      type: type,
      dateStart: date,
      dateEnd: date,
    });
    setCreatedStart(date);
    setCreatedEnd(date);
  }
  const conditionCheck = () => {
    if(createdStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: 'blue',
        text: "Please select Created Start"
      });
      reset({
        module: module,
      functionName: functionName,
      severity: severity,
      type: type,
      dateStart: "",
      dateEnd: "",
      });
      setCreatedEnd("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(module), [module], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(functionName),
    [functionName],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(severity), [severity], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(type), [type], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdStart),
    [createdStart],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(createdEnd), [createdEnd], 500);

  useEffect(() => {
    setParams({
      ...params,
      eventModule: module,
      eventFunction: functionName,
      eventType: type,
      eventSeverity: severity,
      dateFrom: createdStart,
      dateTo: createdEnd,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="module"
          errors={errors}
          control={control}
          required
          placeholder="Module"
          maxLength={100}
          isLoading={false}
          onChange={handleModule}
        />
        <InputForm
          name="functionName"
          errors={errors}
          control={control}
          required
          placeholder="Function"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionNameSelect}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Severity"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleSeveritySelect}
          isClear
        />
        <InputForm
          name="type"
          errors={errors}
          control={control}
          required
          placeholder="Type"
          maxLength={100}
          isLoading={false}
          onChange={handleTypeSelect}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateEnd}
        />
      </Box>
    </Box>
  );
};

export default ApplicationLogSearch;
