import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import Navbar from "../../../components/organism/navbar/Navbar";
import Sidebar from "../../../components/organism/sidebar/Sidebar";
import { getToken, setRefreshToken, setToken } from "../../../utils/storage";
import { useGetDashboardInfoMutation } from "../../../store/feature/service/dashboard";
import { Typography } from "@mui/material";
// import { useGetRoleListQuery } from "../../../store/feature/service/adminManagementApiSlice";
// import { useGetProfileDetailMutation } from "../../../store/feature/service/userApiSlice";
// import { getRoleType } from '../../../store/feature/user';

const MainContainer = () => {
  // REGION: INIT DATA
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [paddingRight, setPaddingRight] = useState(0);
  const token = getToken();
  const { pathname, search } = useLocation();
  const [getDashboardInfo, { data }] = useGetDashboardInfoMutation();

  function useQuery() {
    return new URLSearchParams(search);
  }

  const query = useQuery();

  // const { data: dataRole } = useGetRoleListQuery({});
  // const [
  //   getProfileDetail,
  //   {
  //     data: detailPayload,
  //     isLoading: detailLoading,
  //     isSuccess: detailSuccess,
  //     isError: detailError,
  //   },
  // ] = useGetProfileDetailMutation();

  // console.log('detail payload bor ===> ', detailPayload)
  // console.log('dataRole bor ===> ', dataRole)

  // useEffect(() => {
  //   if (token !== "") {
  //     getProfileDetail({});
  //   }
  // }, []);

  useEffect(() => {
    // if (token === "") {
    //   navigate("/login");
    // }
    const tokenQuery = query.get("token");
    const refreshTokenQuery = query.get("refresh_token");
    if (tokenQuery !== null && refreshTokenQuery !== null) {
      setToken(tokenQuery);
      setRefreshToken(refreshTokenQuery);
      getDashboardInfo({});
      navigate("/");
    } else if (token === "") {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (open) {
      setPaddingRight(15);
    } else {
      setPaddingRight(0);
    }
  }, [open]);

  const isNotLogin = {
    paddingY: "16px",
    paddingLeft: "20px",
    paddingRight: `${paddingRight}px`,
    paddingTop: "40px",
    width: "95%",
  };

  const isLogin = {
    padding: "0",
    width: "100%",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        // backgroundImage: `url(${entrustLogo})`,
        // position: "relative", // Add relative positioning
        // minHeight: "99vh", // Ensure the Box takes up the full height of the viewport,
        // backgroundSize: "180px 50px", // Set the size of the background image
        // backgroundPosition: "bottom right", // Position the background image at the bottom center
        // backgroundRepeat: "no-repeat", // Prevent the image from repeating
      }}
    >
      <CssBaseline />
      {token !== "" && (
        <>
          <Navbar open={open} setOpen={setOpen} />
          <Sidebar open={open} setOpen={setOpen} />{" "}
        </>
      )}
      <Box
        boxSizing="border-box"
        sx={pathname === "/login" ? isLogin : isNotLogin}
      >
        <Outlet />
      </Box>
      {/* <Typography>variant</Typography>
      <Box component="img" src={entrustLogo} sx={{ width: "100PX", height: '100px' }} /> */}
    </Box>
  );
};

export default MainContainer;
