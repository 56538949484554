import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeleteGeneratedDigsigMutation } from "../../../store/feature/service/generateDigsigApiSlice";
import { useEffect, useState } from "react";

const GeneratedDigsigTable = ({ data, setDataParam }: any) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const [
    deleteGeneratedDig,
    { isSuccess: successDeleteGeneratedDig, isError: errorDeleteGeneratedDig },
  ] = useDeleteGeneratedDigsigMutation();

  // Define columns
  const columns: GridColDef[] = [
    {
      field: "fieldParamFor",
      headerName: "Field Parameter For",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
      renderCell: (params) => {
        // Conditionally render Field Parameter For to only show once for duplicates
        const index = params.api.getRowIndex(params.id);
        const prevRow: any = rows[index - 1];
        if (
          index > 0 &&
          prevRow &&
          prevRow.fieldParamFor === params.row.fieldParamFor
        ) {
          return ""; // If the previous row has the same value, render nothing
        }
        return params.value; // Otherwise, show the value
      },
    },
    {
      field: "fieldSpec",
      headerName: "Field Specification",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "inptValType",
      headerName: "Value Type (Editable)",
      flex: 1,
      minWidth: 250,
      type: "singleSelect",
      editable: true,
      valueOptions: ["no select", "string", "number", "array", "json", "null"],
    },
  ];

  // Map data into rows, ensuring that inptValType is not undefined or null
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  const mappedRows = dataRows.map((row: any, index: any) => {
    const prevRow = dataRows[index - 1];

    // Mark the row as editable only if the previous 'fieldParamFor' value is different
    const isEditable = !(
      index > 0 &&
      prevRow?.dsg_ddd_field_param_definition_label ===
        row.dsg_ddd_field_param_definition_label
    );

    return {
      id: index,
      uid: row.dsg_ddd_uid,
      fieldParamFor: row.dsg_ddd_field_param_definition_label,
      paramUid: row.dsg_ddd_field_param_definition_uid,
      fieldSpec: row.dsg_ddd_param_fieldspec_name,
      inptValType: row.dsg_ddd_entry_created_at || "", // Default to "string" if undefined
      isEditable, // Add isEditable flag
    };
  });

  useEffect(() => {
    setRows(mappedRows);
  }, [data]);

  // Handle cell edit commit (value change)
  const handleCellEditCommit = (params: any) => {
    const updatedRows: any = rows.map((row: any) =>
      row.id === params.id ? { ...row, [params.field]: params.value } : row
    );
    setRows(updatedRows);
    // setData((prevParams: any) => ({
    //   ...prevParams,
    //   [params.field]: params.value, // Update the params based on the edited cell
    // }));
    // setData(updatedRows)
    // console.log("Updated Rows: ", updatedRows.map((item: any)=> ({
    //   fieldParameter: item.inptValType,
    //   inputVal: item.
    // })));
    const result = updatedRows.reduce((acc: any, item: any) => {
      if (item.isEditable) {
        const existing = acc.find((entry: any) => entry.fieldParamFor === item.fieldParamFor);
        if (!existing) {
          acc.push({ fieldParamFor: item.fieldParamFor, inputValType: item.inptValType, paramUid: item.paramUid });
        }
      }
      return acc;
    }, []);
    console.log('result ===> ', result)
    setDataParam(result)
  };

  useEffect(() => {
    if (successDeleteGeneratedDig) {
      Swal.fire({
        icon: "success",
        title: "Success Delete",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Digsig Certificate",
      }).then(() => navigate("/digsig-certificate"));
    }
  }, [successDeleteGeneratedDig]);

  useEffect(() => {
    if (errorDeleteGeneratedDig) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Digsig Certificate",
      });
    }
  }, [errorDeleteGeneratedDig]);

  // Use isCellEditable to determine editability at a cell level
  const isCellEditable = (params: GridCellParams) => {
    const row: any = rows.find((row: any) => row.id === params.id);
    return row?.isEditable || false;
  };

  return (
    <>
      <Box sx={{ height: "300px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          onCellEditCommit={handleCellEditCommit}
          isCellEditable={isCellEditable} // Set cell-level editability
        />
      </Box>
    </>
  );
};

export default GeneratedDigsigTable;
