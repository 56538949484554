import { Box, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

interface RowData {
  id: number;
  name: string;
  age: number;
  joinDate: Date;
  role: string;
  isNew?: boolean; // Optional property
}

const DigsigCSVTable = ({ data, setData }: any) => {
  const [rows, setRows] = useState<RowData[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rowModesModel, setRowModesModel] = useState<any>({});

  // Function to handle delete action
  const handleDelete = (rowId: number) => {
    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure you want to delete this entry?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
        Swal.fire("Deleted!", "The entry has been deleted.", "success");
      }
    });
  };

  // Function to handle edit action
  const handleEdit = (rowId: number) => {
    setRowModesModel((prevModel: any) => ({
      ...prevModel,
      [rowId]: { mode: "edit" },
    }));
  };

  // Function to save edited row
  const handleSave = (id: number) => () => {
    setRowModesModel((prevModel: any) => ({
      ...prevModel,
      [id]: { mode: "view" },
    }));
  };

  // Function to cancel editing
  const handleCancel = (id: number) => () => {
    setRowModesModel((prevModel: any) => ({
      ...prevModel,
      [id]: { mode: "view", ignoreModifications: true },
    }));

    const isNew = rows.find((row) => row.id === id)?.isNew;
    if (isNew) {
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    }
  };

  // Handle row update after inline edit
  const processRowUpdate = (newRow: GridRowModel) => {
    console.log("Row updated: ", newRow); // Debugging

    // Update the state with the new row data
    const updatedRows: any = rows.map((row) =>
      row.id === newRow.id ? { ...newRow, id: row.id } : row
    );
    console.log('updateRows ===> ', JSON.stringify(updatedRows))
    setRows(updatedRows);
    setData(updatedRows.map(({ id, ...rest }: any) => rest))

    // Return the updated row
    return newRow;
  };

  // Set rows and columns based on data
  useEffect(() => {
    if (data && data.length > 0) {
      const dynamicColumns: any = Object.keys(data[0]).map((key) => ({
        field: key,
        headerName: key,
        flex: 1,
        minWidth: 150,
        editable: (params: any) =>
          rowModesModel[params.row.id]?.mode === "edit",
      }));

      const actionColumn: GridColDef = {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: (params) => {
          const isInEditMode = rowModesModel[params.row.id]?.mode === "edit";
          return (
            <Stack spacing={2} direction="row">
              {isInEditMode ? (
                <>
                  <IconButton
                    color="primary"
                    onClick={handleSave(params.row.id)}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    onClick={handleCancel(params.row.id)}
                  >
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(params.row.id)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(params.row.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </Stack>
          );
        },
      };

      setColumns([...dynamicColumns, actionColumn]);

      const mappedRows: RowData[] = data.map((row: any, index: number) => ({
        id: index,
        ...row,
      }));

      setRows(mappedRows);
    }
  }, [data, rowModesModel]);

  return (
    <Box sx={{ height: "400px" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        disableColumnSelector
        processRowUpdate={processRowUpdate}
        rowModesModel={rowModesModel}
        onRowModesModelChange={setRowModesModel}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default DigsigCSVTable;
