import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteKeyMngMutation } from "../../../store/feature/service/keyApiSlice";
import { useEffect, useState } from "react";
import {
  useEksportKeyDsaMutation,
  useEksportKeyRsaMutation,
} from "../../../store/feature/service/importKeyApiSlice";
import { useDeleteGeneratedDigsigMutation } from "../../../store/feature/service/generateDigsigApiSlice";

const GeneratedDigsigTable = ({
  data,
  params,
  setParams,
}: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const [pemName, setPemName] = useState("");
  // const { totalConfig } = useAppSelector(
  //   (store: RootState) => store.satpasDevice
  // );
  const [deleteKey, { isSuccess, isError }] = useDeleteKeyMngMutation();

  const [
    deleteGeneratedDig,
    {
      isSuccess: successDeleteGeneratedDig,
      isError: isErrorDeleteGeneratedDig,
      data: dataDeleteGeneratedDig,
      error: errorDeleteGeneratedDig,
    },
  ] = useDeleteGeneratedDigsigMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/key-management/detail/${params.row.uid}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteGeneratedDig(params.row.uid);
        }
        console.log("kid ===> ", {
          kid: params.row.kid,
        });
      });
    };

    const onExport = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // if (params.row.keyType === "RSA") {
      //   eksportRsa({ kid: params.row.kid });
      //   const arrName = params.row.kid.split("/");
      //   setPemName(arrName[arrName.length - 1]);
      //   console.log("rusak RSA ==> ", arrName);
      // } else {
      //   eksportDsa({ kid: params.row.kid });
      //   const arrName = params.row.kid.split("/");
      //   setPemName(arrName[arrName.length - 1]);
      // }
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            View
          </Button>
          {params.row.status === "generated" ? (
            <Button variant="contained" onClick={onExport}>
              Download base64...
            </Button>
          ) : (
            <Button variant="contained" onClick={onExport}>
              Update
            </Button>
          )}
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "entryName",
      headerName: "Entry Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "cratedAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        if (params.row.status !== null) {
          switch (params.row.status.toLowerCase()) {
            case "generated": {
              return (
                <Chip label="Generated" color="success" variant="outlined" />
              );
            }
            case "not generated": {
              return (
                <Chip
                  label="Not Generated"
                  color="warning"
                  variant="outlined"
                />
              );
            }
          }
        } else {
          return "";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 370,
      headerAlign: "center",
      // align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data?.data);
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  const rows = dataRows.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    uid: row.dsg_ddd_uid,
    entryName: row.dsg_ddd_entry_name,
    company: row.dsg_ddd_entry_company,
    cratedAt: row.dsg_ddd_entry_created_at,
    status: row.dsg_status,
  }));

  useEffect(() => {
    if (successDeleteGeneratedDig) {
      Swal.fire({
        icon: "success",
        title: "Success Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Digsig Certificate",
      }).then(()=> navigate('/digsig-certificate'));
    }
  }, [successDeleteGeneratedDig]);

  useEffect(() => {
    if (errorDeleteGeneratedDig) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Digsig Certificate",
      });
    }
  }, [errorDeleteGeneratedDig]);
  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data.total_records}
      />
    </>
  );
};

export default GeneratedDigsigTable;
