
import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';
import { LogRoute } from './log';
import DailyStatics from '../components/molecules/dashboard/DailyStatics';
import ChangePassword from '../pages/profile/ChangePassword';
import { ImportKeyRoute } from './importKey';
import { DdddFieldsRoute } from './dddFields';
import { ImportKeyMngRoute } from './keyMng';
import { DomainAuthorityCertRoute } from './domainAuthorifyCert';
import { DigsigCertRoute } from './digsigCert';
import { generatedDigsigRoute } from './generatedDigsig';

const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <DailyStatics />,
        path: '/statistics'
      },
      {
        element: <Login />,
        path: '/login',
      },
      {
        element: <Profile />,
        path: '/my-profile',
      },
      {
        element: <ChangePassword />,
        path: '/change-password',
      },
      ...ImportKeyRoute,
      ...LogRoute,
      ...DdddFieldsRoute,
      ...ImportKeyMngRoute,
      ...DomainAuthorityCertRoute,
      ...DigsigCertRoute,
      ...generatedDigsigRoute
    ],
  },
];

export default routes;
