import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
// import satpasDevice from "../../utils/validation/satpasDevice";
import keyMng from "../../utils/validation/keyMng";
import { useGetKeyMngByUidMutation } from "../../store/feature/service/keyApiSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";

const GeneratedDigsigField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("detail");

  // const {
  //   editDataOcspConfig,
  //   isLoadingOcspConfig,
  //   isLoadingEvent,
  //   eventFlag
  // } = useAppSelector((state: RootState)=> state.satpasDevice)
  // const {
  //   uc_ocsp_config_uid,
  //  } = editDataOcspConfig
  const [getKeyMngByUid, { data, isError, isSuccess }] =
    useGetKeyMngByUidMutation();

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    keyName: string;
    createdDate: string;
    expiredDate: string;
    revokeDate: string;
    revokeReason: string;
    dddName: string;
    kcv: string;
    reasonSelect: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(keyMng),
    defaultValues: {
      keyName: "",
      createdDate: "",
      expiredDate: "",
      revokeDate: "",
      revokeReason: "",
      dddName: "",
      kcv: "",
      reasonSelect: "Active",
    },
  });

  // REGION: SET FORM DATA
  // useEffect(() => {
  //   if (editFgroup) {
  //     reset({
  //       uid: uc_ocsp_config_uid,
  //       encryptedString: "",
  //       publicKey: "",
  //     });
  //   } else {
  //     reset();
  //   }
  // }, [ editDataOcspConfig ]);

  // REGION: CHANGE STATE
  useEffect(() => {
    getKeyMngByUid(idParam.id);
  }, [idParam]);

  useEffect(() => {
    if (data?.data) {
      console.log("data ===> ", data);
      const keyData = data?.data;
      setValue("keyName", keyData.dsg_key_name);
      setValue("createdDate", keyData.dsg_key_creation_date);
      setValue("expiredDate", keyData.dsg_key_expiry_date);
      setValue("revokeDate", keyData.dsg_key_revoke_date);
      setValue("revokeReason", keyData.dsg_key_revoke_reason);
      setValue("dddName", keyData.dsg_ddd_name);
      setValue("kcv", keyData.dsg_key_kcv);
      // setValue("reasonSelect", state.createdAt);
    }
  }, [data]);

  // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if(eventFlag) {
  //     navigate("/key")
  //     dispatch(removeEventFlag())
  //   }
  // }, [eventFlag]);

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    // if (editFgroup) {
    //   const updateData = { }
    //   dispatch(updateOcspConfiguration(updateData));
    // } else {
    //   const createData = { }
    //   dispatch(createOcspConfiguration(createData));
    // }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Confirm revoke key",
      html: `
        <p style="margin-bottom: 10px; text-align: left;">
          You're about to permanently mark this keypair as invalid and untrustworthy before its intended expiration time.
        </p>
        <p style="margin-bottom: 10px; text-align: left;">
          <strong>Are you sure you want to revoke this key? <br/> This action cannot be undone.</strong> 
        </p>
        <p style="text-align: left;">
          If you're not sure, you can select cancel instead.
        </p>
      `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      iconHtml: renderToStaticMarkup(
        <CancelIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, revoke key!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("Key revoked");
      } else {
        Swal.fire({
          title: "Your changes won’t be saved",
          html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#808080",
          iconHtml: renderToStaticMarkup(
            <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
          ),
          confirmButtonText: "Discard Data",
          cancelButtonText: "Go back",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            console.log("Data discarded");
          } else {
            showFirstAlert(); // Call the first alert again
          }
        });
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  const handleDestroy = (e: any) => {
    console.log("handle revoke ===> ");
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {/* {editFgroup ? "Update Key" : "Create Key"} */}
          Detail Key
        </Typography>
        <InputForm
          name="keyName"
          label="Key Name"
          placeholder="Type Key Name"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />

        <InputForm
          name="createdDate"
          label="Key Created Date"
          placeholder="Type Key Created Date"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="expiredDate"
          label="Key Expired Date"
          placeholder="Type Key Expired Date"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="revokeDate"
          label="Key Revoke Date"
          placeholder="Type Key Revoke Date"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="revokeReason"
          label="Key Revoke Reason"
          placeholder="Type Key Revoke Reason"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="dddName"
          label="DDD Name"
          placeholder="Phone Number"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {/* <InputForm
          name="dddName"
          label="DDD Name"
          placeholder="Phone Number"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        /> */}
        <TextAreaForm
          name="kcv"
          label="KCV"
          defaultValue=""
          placeholder="type KCV"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={1000000}
          required
        />
        {/* <SelectFormm
          name="status"
          label="Status"
          defaultValue={0}
          options={optStatusSelect}
          placeholder="none"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          required
        /> */}
        <InputForm
          name="reasonSelect"
          label="Revoke Reason Select"
          placeholder="Type Revoke Reason Select"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/key")}
          >
            Back
          </Button> */}
          <Button
            variant="contained"
            type="button"
            color="error"
            onClick={handleRevoke}
          >
            Revoke
          </Button>
          <Button
            variant="contained"
            type="button"
            color="error"
            onClick={handleDestroy}
          >
            Destroy
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default GeneratedDigsigField;
