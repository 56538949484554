import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";

const KeyUsageLogTable = ({
  data,
  params,
  setParams,
}: any) => {
  // REGION: INIT VARIABLES


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "keyName",
      headerName: "Key Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "certCN",
      headerName: "Certificate Common Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "historyDate",
      headerName: "History Date",
      flex: 1,
      minWidth: 200,
    },
  ];

  // REGION: INIT ROWS
  const dataRow = data?.events !== undefined ? data?.events : [];
  const totalConfig = data?.events !== undefined ? data?.total_records : 0;
  const rows = dataRow?.map((row: any, index: any) => ({
    id: index,
    keyName: row.evt_module,
    certCN: row.event_function,
    historyDate: row.event_time,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalConfig}
      />
    </>
  );
};

export default KeyUsageLogTable;
