import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import keyMng from "../../utils/validation/keyMng";
import { useGetKeyMngByUidMutation } from "../../store/feature/service/keyApiSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { useGetDddNameListQuery } from "../../store/feature/service/dddFields";
import GeneratedDigsigUploadTable from "../../components/molecules/generatedDigsig/GeneratedDigsigUploadTable";
import {
  useDownloadGenCSVMutation,
  useGetDddParamDefUidMutation,
} from "../../store/feature/service/dddParamDefApiSlice";
import Papa from "papaparse";
import DigsigCSVTable from "../../components/molecules/generatedDigsig/DigsigCSVTable";
import generateDigsig from "../../utils/validation/generateDigsig";
import { useCreateGenDigMutation } from "../../store/feature/service/generateDigsigApiSlice";

const GeneratedDigsigUpload = () => {
  // REGION: INIT VARIABLES
  const location = useLocation();
  const navigate = useNavigate();
  const idParam = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("detail");
  const [optDDDName, setOptDDDName] = useState([]);
  const [dataParamDefTable, setDataParamDefTable] = useState([]);
  const [cert, setCert] = useState("");
  const [dDDFieldUid, setDDDFieldUid] = useState("");
  const [dataParam, setDataParam] = useState([]);

  // const {
  //   editDataOcspConfig,
  //   isLoadingOcspConfig,
  //   isLoadingEvent,
  //   eventFlag
  // } = useAppSelector((state: RootState)=> state.satpasDevice)
  // const {
  //   uc_ocsp_config_uid,
  //  } = editDataOcspConfig
  const { data, isError, isSuccess } = useGetDddNameListQuery({});
  const [
    GetDddParamDefUid,
    { data: dataParamDef, isError: errorParamDef, isSuccess: successParamDef },
  ] = useGetDddParamDefUidMutation();

  const [
    DownloadGenCSV,
    { data: dataDownload, isError: errorDownload, isSuccess: successDownload },
  ] = useDownloadGenCSVMutation();

  const [
    CreateGenDig,
    {
      data: dataCreateGen,
      isError: errorCreateGen,
      isSuccess: successCreateGen,
    },
  ] = useCreateGenDigMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    compName: string;
    dddField: string;
    uploadCSV: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(generateDigsig),
    defaultValues: {
      compName: "",
      dddField: "",
      uploadCSV: "",
    },
  });

  // REGION: CHANGE STATE
  useEffect(() => {
    if (data) {
      setOptDDDName(
        data?.data.map((item: any) => ({
          label: item.dsg_ddd_name,
          value: item.dsg_ddd_uid,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (dataParamDef) {
      setDataParamDefTable(dataParamDef);
    }
  }, [dataParamDef]);

  useEffect(() => {
    if (dataParam) {
      console.log("dataParam ==> ", dataParam);
    }
  }, [dataParam]);

  useEffect(() => {
    if (dataDownload) {
      console.log("data download ===> ", dataDownload?.data?.template);
      const base64Data = dataDownload?.data?.template;
      if (base64Data) {
        // Decode the base64 string
        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters, (char) =>
          char.charCodeAt(0)
        );
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the byte array (CSV file)
        const blob = new Blob([byteArray], { type: "text/csv" });

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "downloaded_file.csv"; // Set the file name for download
        link.click();

        // Clean up the URL object after the download
        URL.revokeObjectURL(link.href);
      }
    }
  }, [dataDownload]);

  const combineArrays = (arr1: any, arr2: any, compName: string) => {
    // Create a map for quick lookup of paramUid to fieldParamFor and inputValType
    const paramMap = arr1.reduce((map: any, item: any) => {
      map[item.fieldParamFor] = { uid: item.paramUid, type: item.inputValType };
      return map;
    }, {});

    // Process arr2 to create the desired output
    return arr2
      .map((entry: any) => {
        // Get ddd_values based on arr1 mapping
        const ddd_values = Object.keys(paramMap).map((field) => {
          const { uid, type } = paramMap[field];
          const value = entry[field];

          const dsg_ddd_value_data = type === "string" ? value || "" : value;

          let typeMode;

          if (type === "string") {
            typeMode = entry[field];
          } else if (type === "number") {
            typeMode = Number(entry[field]);
          } else if (type === "null") {
            typeMode = null;
          } else if (type === "array") {
            typeMode = [entry[field]];
          } else if (type === "json") {
            typeMode = JSON.parse([entry[field]] as any);
          }

          return {
            dsg_ddd_field_param_definition_uid: uid,
            dsg_ddd_value_data: typeMode,
            dsg_ddd_value_type: type,
          };
        });

        // Return the formatted object
        return {
          ddd_values,
          dsg_ddd_entry_name: entry["entry name"] || "",
          dsg_ddd_entry_company: compName,
          dsg_ddd_uid: dDDFieldUid,
        };
      })
      .filter((item: any) =>
        item.ddd_values.some((value: any) => value.dsg_ddd_value_data)
      ); // Filter out empty entries
  };

  const onSubmit = (e: any) => {
    if (dataParam.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Value Type",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Value Type Must be Filled",
      });
      return;
    }
    // console.log("data param ===> ", JSON.stringify(dataParam));
    if (cert.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Upload File",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please Upload The CSV File first!!!",
      });
      return;
    }
    const combinedArray = combineArrays(dataParam, cert, e.compName);
    console.log("combineArray ===> ", combinedArray);
    CreateGenDig(combinedArray);
  };

  const handleUploadCert = (e: any) => {
    const file = e[0];
    console.log("file here ", file);

    if (file) {
      const reader = new FileReader();
      reader.readAsText(file); // Read the file as text for CSV parsing
      reader.onload = () => {
        const csvData = reader.result;
        if (csvData) {
          Papa.parse(csvData.toString(), {
            header: true, // Optional: Treat the first row as headers
            complete: (results: any) => {
              const json = results.data;
              // setValue("uploadCert", json); // Set the parsed JSON to the form field
              setCert(json);
              console.log("Parsed JSON: ", JSON.stringify(json)); // Optional: log the JSON for debugging
            },
            error: (error: any) => {
              console.error("Error parsing CSV:", error);
            },
          });
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };
  const hanldeChangeDDDName = (e: any) => {
    console.log("e ===> ", e);
    setDDDFieldUid(e.value);
    GetDddParamDefUid(e.value);
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Confirm revoke key",
      html: `
        <p style="margin-bottom: 10px; text-align: left;">
          You're about to permanently mark this keypair as invalid and untrustworthy before its intended expiration time.
        </p>
        <p style="margin-bottom: 10px; text-align: left;">
          <strong>Are you sure you want to revoke this key? <br/> This action cannot be undone.</strong> 
        </p>
        <p style="text-align: left;">
          If you're not sure, you can select cancel instead.
        </p>
      `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      iconHtml: renderToStaticMarkup(
        <CancelIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, revoke key!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("Key revoked");
      } else {
        Swal.fire({
          title: "Your changes won’t be saved",
          html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#808080",
          iconHtml: renderToStaticMarkup(
            <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
          ),
          confirmButtonText: "Discard Data",
          cancelButtonText: "Go back",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            console.log("Data discarded");
          } else {
            showFirstAlert(); // Call the first alert again
          }
        });
      }
    });
  };

  useEffect(() => {
    if (successCreateGen) {
      Swal.fire({
        icon: "success",
        title: "Successfully Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Successfully Upload Digsig Value",
      }).then(() => navigate("/digsig-data"));
    }
  }, [successCreateGen]);

  useEffect(() => {
    if (errorCreateGen) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Digsig Certificate",
      });
    }
  }, [errorCreateGen]);

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  const handleDestroy = (e: any) => {
    console.log("handle revoke ===> ", cert);
  };

  const handleDownloadTemplate = (e: any) => {
    DownloadGenCSV(dDDFieldUid);
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {/* {editFgroup ? "Update Key" : "Create Key"} */}
          Create Digsig Value
        </Typography>
        <InputForm
          name="compName"
          label="DIGSIG Company Name/ID"
          placeholder="Type DIGSIG Company Name/ID"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />

        <SelectFormm
          name="dddField"
          label="DDD Field"
          defaultValue={0}
          options={optDDDName}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          onChangeForm={hanldeChangeDDDName}
          required
        />

        {dataParamDefTable.length !== 0 && (
          <Grid container spacing={2} sx={{ mb: "10px" }}>
            <Grid item lg={12}>
              <GeneratedDigsigUploadTable
                data={dataParamDefTable}
                setData={setDataParamDefTable}
                dataParam={dataParam}
                setDataParam={setDataParam}
              />
            </Grid>
            <Grid item lg={12} display={"flex"} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                type="button"
                color="success"
                onClick={handleDownloadTemplate}
              >
                Download Template
              </Button>
            </Grid>
            <Grid item lg={12} mb={"20px"}>
              {cert.length === 0 ? (
                <InputForm
                  name="uploadCSV"
                  placeholder="Type DIGSIG Company Name/ID"
                  disabled={editFgroup}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  maxLength={500}
                  required
                  type="file"
                  onChange={handleUploadCert}
                />
              ) : (
                <DigsigCSVTable data={cert} setData={setCert} />
              )}
            </Grid>
          </Grid>
        )}

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mb={"20px"}
        >
          {/* <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/key")}
          >
            Back
          </Button> */}
          <Button
            variant="contained"
            type="button"
            color="gray"
            onClick={handleRevoke}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            // onClick={handleDestroy}
          >
            Submit & Generate
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default GeneratedDigsigUpload;
