import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";
import { ILogin } from "../reducers/userReducer";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        submitLogin: builder.mutation<any, any>({
            query: credentials => {
                const { Email, Password } = credentials;
                const pasEnc = encrypt(Password);
                return ({
                    url: '/authentication/login',
                    method: 'POST',
                    body: {
                        email: Email,
                        password: Password,
                    },
                })
            },
            invalidatesTags:['submitLogin', 'GetDashboardInfo']
        })
    })
})

export const { useSubmitLoginMutation } = userApiSlice